<template>
  <b-modal id="mainoeuvreModal" ref="mainoeuvreModal" hide-footer @hide="close">
    <template #modal-header>
      <h5>
        {{ $t("Ajouter") }} {{ $t("OU") }} {{ $t("RETIRER") }} {{ $t("DES") }}
        {{ $t("mainoeuvre") }}
      </h5>
      <b-button size="sm" @click="resetModal()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17.028"
          height="17.028"
          viewBox="0 0 17.028 17.028"
        >
          <path
            id="Icon_material-close"
            data-name="Icon material-close"
            d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
            transform="translate(-7.5 -7.5)"
            fill="#393939"
          />
        </svg>
      </b-button>
    </template>

    <form>
      <div class="center">
        <div class="input-group">
          <b-form-input
            v-model="search"
            @keyup="debounceInput"
            :placeholder="$t('SEARCH')"
            class="inputsearch"
          ></b-form-input>
          <font-awesome-icon icon="search" class="icon-search" v-if="!search" />
        </div>

        <table class="tab">
          <thead>
            <tr>
              <th>
                {{ $t("WORDING") }}
              </th>
              <th>
                {{ $t("ACTIVER") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              role="row"
              class="ligneNormale"
              v-for="(data, i) in getAllmainoeuvres"
              :key="i"
            >
              <td aria-colindex="1" role="cell">
                {{ data.lib }}
              </td>
              <td aria-colindex="1" role="cell">
                <b-form-checkbox
                  v-model="data.active"
                  name="check-button"
                  @change="active(data)"
                  switch
                >
                </b-form-checkbox>
              </td>
            </tr>
          </tbody>
        </table>
        <b-pagination
          v-model="pageactive"
          :total-rows="getTotalRowmainoeuvre"
          :per-page="perPageactive"
          aria-controls="my-table"
          pills
          last-number
          first-number
          align="center"
          size="sm"
          @change="pagination"
          class="pagination"
        ></b-pagination>
      </div>

      <div class="actionModel">
        <b-alert variant="warning" show v-if="errorS">
          {{ errorS }}
        </b-alert>
        <b-button variant="success" @click="resetModal()">
          <div class="block-spinner">
            {{ $t("CLOSE") }}
            <div v-if="getmainoeuvreLoading" class="loading ml-2">
              <div class="spinner-border" role="status"></div>
            </div>
          </div>
        </b-button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";

export default {
  data() {
    return {
      errorS: false,
      search: null,
      pageactive: 1,
      perPageactive: 10,
      box: "",
    };
  },
  methods: {
    ...mapActions(["all_mainoeuvres", "edit_mainoeuvre"]),
    debounceInput: _.debounce(function (e) {
      this.search = e.target.value;
      if (!this.search) {
        this.all_mainoeuvres({
          page: this.pageactive,
          per_page: this.perPageactive,
        });
      } else {
        this.all_mainoeuvres({
          page: this.pageactive,
          per_page: this.perPageactive,
          search: this.search,
        });
      }
    }, 500),

    resetModal() {
      this.search = null;
      this.$emit("updateList");
      this.$refs["mainoeuvreModal"].hide();
    },

    close(bv) {
      if (this.$refs["mainoeuvreModal"].isShow) {
        if (bv.trigger == "backdrop") {
          bv.preventDefault();
          this.showMsgBox();
        }
      }
    },

    showMsgBox() {
      this.box = "";
      this.$bvModal
        .msgBoxConfirm(`${this.$t("CLOSE")}`, {
          title: `${this.$t("CONFIRM")}`,
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          cancelVariant: "danger",
          okTitle: `${this.$t("YES")}`,
          cancelTitle: `${this.$t("NO")}`,
          footerClass: "p-2",
          hideHeaderClose: false,
        })
        .then((value) => {
          if (value) {
            this.resetModal();
          }
        });
    },

    active(def) {
      var data = {
        id: def.id,
        active: def.active == true ? 1 : 0,
      };

      this.edit_mainoeuvre(data);
    },
    pagination(paginate) {
      this.pageactive = paginate;
      this.all_mainoeuvres({
        page: this.pageactive,
        per_page: this.perPageactive,
        search: this.search,
      });
    },
  },

  computed: {
    ...mapGetters([
      "getmainoeuvreLoading",
      "getTabs",
      "getAllmainoeuvres",
      "getTotalRowmainoeuvre",
    ]),
  },
};
</script>
<style lang="scss" scoped>
.pagination {
  margin-top: 11px;
}
.input-group {
  width: 100%;
  position: relative;
  margin-bottom: 0;
  height: 35px;
  .inputsearch {
    border-radius: 5px !important;
    padding: 0 28px;
    background-color: #f5f6f8;
  }
  .icon-search {
    overflow: visible;
    position: absolute;
    left: 11px;
    top: 13px;
    color: #515151;
  }
}
.tab {
  margin-top: 15px;
}
</style>
